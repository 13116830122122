import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAccountInfo, AccountState } from 'modules/account';
import { useSelector } from 'react-redux';
import { trackPage } from '../utils/analytics';

const getAdminId = (account: AccountState | undefined) =>
  account && account.adminId ? account.adminId : '';

const useTrackPage = () => {
  const [, setCurrentPath] = useState<string>('');
  const history = useHistory();
  const account = useSelector(getAccountInfo);
  const accountRef = useRef<AccountState | undefined>();
  accountRef.current = account;

  useEffect(() => {
    let path = history.location.pathname + history.location.search;
    setCurrentPath(path);
    let adminId = getAdminId(accountRef.current);
    trackPage(path, adminId);
    history.listen(async ({ pathname, search }) => {
      setCurrentPath(preCurrentPath => {
        path = pathname + search;
        if (preCurrentPath !== path) {
          adminId = getAdminId(accountRef.current);
          trackPage(path, adminId);
          return path;
        }
        return preCurrentPath;
      });
    });
  }, [history, setCurrentPath]);
};

export default useTrackPage;
