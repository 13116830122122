/* eslint-disable import/export */
import { ActionCreatorsMapObject } from 'redux';

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<
  A[keyof A]
>;

export function createAction<T extends string>(type: T): FSA<T>;
export function createAction<T extends string, P>(
  type: T,
  payload: P,
  meta?: FSAMeta,
): FSA<T, P>;
export function createAction<T extends string, P>(
  type: T,
  payload?: P,
  meta?: FSAMeta,
) {
  const result = { type };
  // @ts-ignore
  if (payload !== undefined) result.payload = payload;
  // @ts-ignore
  if (meta !== undefined) result.meta = meta;
  return result;
}

export function createActionMeta<T extends string>(
  type: T,
  meta: FSAMeta,
): FSA<T> {
  return { type, meta, payload: undefined };
}
