import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';

const tabUnderLine = css`
  &::after {
    content: '';
    display: block;
    height: 3px;
    margin: 0 auto;
    border-radius: 8px;
    background-color: ${theme.dark};
  }
`;

const FilterTab = styled.div<{ isSelected?: boolean }>`
  width: 140px;
  height: 48px;
  color: ${({ isSelected }) => (isSelected ? theme.dark : theme.light)};
  font-weight: bold;
  line-height: 48px;
  text-align: center;
  cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
  ${tabUnderLine}

  &::after {
    width: ${({ isSelected }) => (isSelected ? '100%' : 0)};
  }

  &:hover {
    color: ${theme.dark};
    ${tabUnderLine}

    &::after {
      width: 100%;
      transition: 0.3s;
    }
  }
`;

export default FilterTab;
