import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Container } from 'visits-style';
import { Footer } from 'components/organisms';
import theme from 'styles/theme';

const Contents = styled.section`
  width: 100%;
  max-width: 520px;
  margin: 50px auto;
  padding: ${theme.headerHeight + 60}px 20px 0 20px;
`;

const StyledContainer = styled(Container)`
  margin-bottom: auto;
`;

const PublicRouteLayout: FC<{}> = ({ children }) => (
  <>
    <StyledContainer>
      <Contents>{children}</Contents>
    </StyledContainer>
    <Footer />
  </>
);

export default PublicRouteLayout;
