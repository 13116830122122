import { createGlobalStyle } from 'styled-components/macro';
import { normalizeStyle } from 'visits-style';
import colors from './colors';

export default createGlobalStyle`
  ${normalizeStyle}
  body {
    position: relative;
    background-color: ${colors.bodyColor};
  }
  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  a {
    color: ${colors.primary};
  }
`;
