import styled from 'styled-components/macro';

const ContentsTitle = styled.h1`
  margin-bottom: 32px;
  font-size: 26px;
  font-weight: normal;
  text-align: center;
`;

export default ContentsTitle;
