/* eslint-disable */
import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import 'i18n/i18n';
/* eslint-enable */
import App from './App';
import apiFirebase from './api/firebase';

apiFirebase.init();
const root = document.getElementById('root');
if (root) {
  ReactDOM.render(<App />, root);
}
