import React from 'react';
import styled from 'styled-components/macro';
// @ts-ignore
import { Link, withRouter } from 'react-router-dom';
import color from 'styles/colors';

const AppFooter = styled.footer`
  width: 100%;
  padding: 0 40px 27px 40px;
  background: ${color.bodyColor};
  font-size: 12px;
`;
const AppFooterInner = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Nav = styled.ul`
  display: flex;
`;
const NavItem = styled.li`
  margin-right: 50px;
`;
const CopyRight = styled.div``;

export function Footer() {
  return (
    <AppFooter>
      <AppFooterInner>
        <Nav>
          <NavItem>
            <Link to="/">お問い合わせ</Link>
          </NavItem>
          <NavItem>
            <Link to="/">利用規約</Link>
          </NavItem>
          <NavItem>
            <Link to="/">Privacy Policy</Link>
          </NavItem>
        </Nav>
        <CopyRight>©︎2019 VISITS Technologies Inc.</CopyRight>
      </AppFooterInner>
    </AppFooter>
  );
}

export default withRouter(Footer);
