import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createReducer from './modules';

export default function createStores(initialState = {}) {
  const middlewares: any[] = [];
  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  // prettier-ignore
  const composeEnhancers = (
    process.env.NODE_ENV !== 'production'
    && typeof window === 'object'
    // @ts-ignore
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      // @ts-ignore
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose
  );
  /* eslint-enable */

  const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whiteList: ['account'],
  };

  const persistedReducer = persistReducer(persistConfig, createReducer());

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(...enhancers),
  );

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept('./modules', () => {
      // @ts-ignore
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
