import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslate from './en.json';
import jaTransltate from './ja.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslate,
    },
    ja: {
      translation: jaTransltate,
    },
  },
  // TODO(mayah): need to determine `lng` from browser language?
  // Without this, error is emitted in the console.
  lng: 'ja',
  fallbackLng: 'ja',
  debug: process.env.REACT_APP_I18N_DEBUG === 'true',
});

export default i18n;
