import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { ContentsTitle } from 'components/atoms';
import { EXPIRED_URL } from 'constants/messages';

const Content = styled.div`
  margin: 0 auto;
`;
const ContentsDescription = styled.p`
  margin-bottom: 38px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  white-space: pre-line;
`;

const ExpiredUrl: FC<{}> = () => {
  return (
    <>
      <ContentsTitle>有効期限切れ</ContentsTitle>
      <Content>
        <ContentsDescription>{EXPIRED_URL}</ContentsDescription>
      </Content>
    </>
  );
};

export default ExpiredUrl;
