import React from 'react';
import { TiWarning } from 'react-icons/ti';
import { SidebarNavLink } from 'components/atoms';
import * as PROTECTED_ROUTES from 'constants/routes/protected';
import styled from 'styled-components/macro';
import scrollbar from 'styles/scrollbar';

const Container = styled.div`
  ${scrollbar}
  width: 290px;
  min-width: 290px;
  padding: 10px 0;
  overflow-y: scroll;
  background: #2f3032;
  color: white;
  font-size: 13px;
  line-height: 1.5;
`;

const MenuGroup = styled.ul`
  margin-bottom: 8px;
`;

const MenuGroupLabel = styled.span`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 24px;
  background: #2f3032;
  color: white;

  &::before {
    content: '◆';
    margin-right: 8px;
    color: teal;
    font-size: 0.8em;
  }
`;

const MenuItem = styled.li``;

const DangerIcon = styled(TiWarning)`
  margin-right: 4px;
  margin-bottom: 2px;
  color: orangered !important;
  font-size: 16px;
`;

export default function Sidebar() {
  return (
    <Container>
      <MenuGroupLabel>アカウント関連</MenuGroupLabel>
      <MenuGroup>
        <MenuItem>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_DETAIL}>
            自分のアカウント情報を表示する
          </SidebarNavLink>
        </MenuItem>
        <MenuItem>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_SEARCH_ACCOUNT}>
            アカウントを検索する
          </SidebarNavLink>
        </MenuItem>
        <MenuItem>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_UPDATE_USER_EMAIL}>
            ユーザのメールアドレスを変更する
          </SidebarNavLink>
        </MenuItem>
      </MenuGroup>

      <MenuGroupLabel>ミッション</MenuGroupLabel>
      <MenuGroup>
        <MenuItem data-testid="create_mission">
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_CREATE_MISSION}>
            ミッションを作成する
          </SidebarNavLink>
        </MenuItem>
        <MenuItem>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_REGISTRATION_EMAIL}>
            登録完了メールを再送する
          </SidebarNavLink>
        </MenuItem>
        <MenuItem>
          <SidebarNavLink
            to={PROTECTED_ROUTES.PRIVATE_CHANGE_CORPORATE_ATTRIBUTE}
          >
            ミッション企業属性を更新する
          </SidebarNavLink>
        </MenuItem>
        <MenuItem>
          <SidebarNavLink
            to={PROTECTED_ROUTES.PRIVATE_CHANGE_MISSION_USE_PLANS}
          >
            ミッション利用プランを変更する
          </SidebarNavLink>
        </MenuItem>
        <MenuItem data-testid="delete_mission">
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_DELETE_MISSION}>
            <DangerIcon />
            ミッションを削除する
          </SidebarNavLink>
        </MenuItem>
      </MenuGroup>

      <MenuGroupLabel>サポーター</MenuGroupLabel>
      <MenuGroup>
        <MenuItem>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_CREATE_SUPPORTER}>
            サポーターを作成する
          </SidebarNavLink>
        </MenuItem>
      </MenuGroup>

      <MenuGroupLabel>審査</MenuGroupLabel>
      <MenuGroup>
        <MenuItem>
          <SidebarNavLink
            to={PROTECTED_ROUTES.PRIVATE_SCREENING_COMPANIES}
            data-testid="sidebar-link-screening-companies"
          >
            入会申込企業の一覧を表示する
          </SidebarNavLink>
        </MenuItem>
      </MenuGroup>

      <MenuGroupLabel>管理者</MenuGroupLabel>
      <MenuGroup>
        <MenuItem>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_CREATE_ADMIN}>
            新しい管理者を追加する
          </SidebarNavLink>
        </MenuItem>
        <MenuItem>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_DELETE_ADMIN}>
            <DangerIcon />
            管理者を削除する
          </SidebarNavLink>
        </MenuItem>
      </MenuGroup>

      <MenuGroupLabel>その他</MenuGroupLabel>
      <MenuGroup>
        <MenuItem>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_FEATURES}>
            機能設定
          </SidebarNavLink>
        </MenuItem>
        <MenuItem>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_SYSTEM_MESSAGE}>
            システムメッセージを送る
          </SidebarNavLink>
        </MenuItem>
      </MenuGroup>

      <MenuGroupLabel>開発者用</MenuGroupLabel>
      <MenuGroup>
        <MenuItem>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_PING}>
            ping to API server
          </SidebarNavLink>
        </MenuItem>
        <MenuItem>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_NOTIFICATION}>
            notification test
          </SidebarNavLink>
        </MenuItem>
        <MenuItem>
          <SidebarNavLink
            to={PROTECTED_ROUTES.PRIVATE_SEND_MISSION_PUBLISHED_EMAIL}
          >
            新規公開ミッションの通知メールを送信する
          </SidebarNavLink>
        </MenuItem>
        <MenuItem>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_COMMAND}>
            デバッグコマンド
          </SidebarNavLink>
        </MenuItem>
      </MenuGroup>
    </Container>
  );
}
