import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';

type ImageCircleProps = {
  imageSrc: string;
  diameter: string;
  border?: boolean;
};

const ImageCircle = styled.div<ImageCircleProps>`
  box-sizing: border-box;
  border-radius: 50%;
  background-color: ${theme.baseWhite};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  ${({ imageSrc }) =>
    css`
      background-image: url(${imageSrc});
    `}
  ${({ diameter }) =>
    css`
      width: ${diameter};
      height: ${diameter};
    `}
  ${({ border = false }) =>
    border &&
    css`
      border: 1px solid ${theme.borderDefault};
    `}
`;

export default ImageCircle;
