import styled from 'styled-components/macro';
import colors from 'styles/colors';

const ErrorMessage = styled.div`
  width: 100%;
  max-width: 400px;
  height: 21px;
  margin: 0 auto 28px auto;
  color: ${colors.warn};
`;

export default ErrorMessage;
