import React, { Suspense, FC } from 'react';
import { withRouter } from 'react-router-dom';
import { Loading } from 'components/atoms';
import { Header } from 'components/organisms';
import useTrackPage from 'hooks/useTrackPage';
import { getLoading } from 'modules/ui';
import { useSelector } from 'react-redux';

const GlobalLayout: FC = props => {
  const isLoading = useSelector(getLoading);
  useTrackPage();
  return (
    <Suspense fallback={<Loading />}>
      <Header />
      {props.children}
      {isLoading && <Loading />}
    </Suspense>
  );
};

export default withRouter(GlobalLayout);
