import styled from 'styled-components/macro';
import { Radio } from 'visits-style';
import colors from 'styles/colors';

const StyledRadio = styled(Radio)`
  > label > div,
  > label > div.checked {
    width: 14px;
    height: 14px;
    margin-top: 0;
    margin-right: 10px;
    margin-bottom: 0;
    border-color: ${colors.primary};

    > i {
      width: 10px;
      height: 10px;
      background: ${colors.primary};
    }
  }
`;

export default StyledRadio;
