import { initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import {
  Auth,
  User,
  browserLocalPersistence,
  getAuth,
  getIdToken,
  onAuthStateChanged,
  sendPasswordResetEmail,
  setPersistence,
  signInWithCustomToken,
  signOut,
} from 'firebase/auth';
import {
  DocumentChange,
  Firestore,
  collection,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore';
import firebaseConfig from 'api/config/firebase';

export const doAnalytics =
  process.env.NODE_ENV === 'production' &&
  !!process.env.REACT_APP_FIREBASE_MEASUREMENT_ID &&
  window.location.host.indexOf('local') === -1;

let auth: Auth;
let firestore: Firestore;
let analytics: Analytics;

export default {
  init() {
    const firebaseApp = initializeApp(firebaseConfig);
    auth = getAuth(firebaseApp);
    firestore = getFirestore(firebaseApp);
    if (doAnalytics) analytics = getAnalytics(firebaseApp);
  },
  setPersistence() {
    return setPersistence(auth, browserLocalPersistence);
  },
  login(token: string): Promise<any> {
    return signInWithCustomToken(auth, token);
  },
  onAuth(): Promise<User | null> {
    return new Promise(resolve => {
      onAuthStateChanged(auth, user => {
        resolve(user);
      });
    });
  },
  resetPassword(email: string): Promise<void> {
    const actionSettings = {
      url: `${process.env.REACT_APP_BASE_URL}`,
    };
    return sendPasswordResetEmail(auth, email, actionSettings);
  },
  currentToken(): Promise<string> {
    // eslint-disable-next-line
    return getIdToken(auth.currentUser!);
  },
  logout(): Promise<void> {
    return signOut(auth);
  },
  authUser() {
    return auth.currentUser;
  },
  auth() {
    return auth;
  },
  analytics() {
    return analytics;
  },

  getNotifications(
    accountId: string,
    messageType: string,
    onChanges: (changes: DocumentChange[]) => void,
  ) {
    const docRef = collection(
      firestore,
      `account_notifications/${accountId}/${messageType}`,
    );
    return onSnapshot(docRef, snapshot => {
      onChanges(snapshot.docChanges());
    });
  },
};
