import { ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { Checkbox } from 'visits-style';
import colors from 'styles/colors';

type StyledCheckboxProps = ComponentProps<typeof Checkbox>;
const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>`
  &&& {
    min-width: 115px;
    margin-right: 10px;
    margin-bottom: 10px;

    label {
      width: auto;
      margin-right: 0;
      padding-left: 0;

      div {
        margin-left: 0;
        border: none;
        background: ${colors.disabled};

        &.checked {
          background: ${colors.primary};
        }

        svg {
          opacity: 1;
          color: ${colors.white};
        }
      }
    }

    ${({ disabled }) =>
      disabled &&
      css`
        label {
          color: ${colors.disabled};
          div.checked {
            background: ${colors.disabled};
          }
        }
        &:hover {
          cursor: default;
        }
      `}
  }
`;

export default StyledCheckbox;
