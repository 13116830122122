import styled from 'styled-components';

const Tag = styled.div`
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 4px 8px;
  border: 1px solid #888fd1;
  border-radius: 5px;
  background: white;
  color: #888fd1;
`;

export default Tag;
