import React, { Suspense, FC } from 'react';
import * as H from 'history';
import styled from 'styled-components/macro';
import { Container } from 'visits-style';
import { Loading } from 'components/atoms';
import { Sidebar } from 'components/organisms';
import { headerHeight } from 'styles/constants';

const StyledContainer = styled(Container)`
  &&& {
    display: flex;
    max-width: 100%;
    height: calc(100vh - ${headerHeight}px);
    ${({ backgroundColor }: { backgroundColor?: string }) =>
      backgroundColor && `background: ${backgroundColor};`}
  }
`;

const Contents = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px;
  overflow-y: auto;
`;

export const Spacer = styled.section`
  padding: 30px;
`;

const PrivateRouteLayout: FC<{ location: H.Location }> = ({ children }) => {
  return (
    <StyledContainer>
      <Sidebar />
      <Contents>
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </Contents>
    </StyledContainer>
  );
};

export default PrivateRouteLayout;
