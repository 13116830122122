import React, { FC, ComponentProps } from 'react';
import { Table } from 'visits-style';

type AppTableProps = ComponentProps<typeof Table>;

const AppTable: FC<AppTableProps> = ({ children, ...props }) => (
  <Table {...props}>{children}</Table>
);

export default AppTable;
