import styled from 'styled-components/macro';
import { Button as OrgButton } from 'visits-style';
import colors from 'styles/colors';

const Button = styled(OrgButton)`
  ${props => !props.disabled && 'box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);'}
  width: 100%;
  max-width: 220px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: none;
  border-radius: 5px;
  background: ${props => (props.disabled ? colors.disabled : colors.primary)};
  color: ${colors.white};
  font-size: 18px;

  &:hover {
    opacity: 0.8;
  }
`;

export default Button;
