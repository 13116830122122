export const PRIVATE = '/';
export const PRIVATE_DETAIL = '/detail';
export const PRIVATE_CREATE_MISSION = '/mission/create';
export const PRIVATE_CHANGE_MISSION_USE_PLANS =
  '/admin/change_mission_use_plans';
export const PRIVATE_CREATE_SUPPORTER = '/supporter/create';
export const PRIVATE_DELETE_MISSION = '/mission/delete';
export const PRIVATE_CREATE_ADMIN = '/admins/create';
export const PRIVATE_DELETE_ADMIN = '/admins/delete';
export const PRIVATE_CHANGE_CORPORATE_ATTRIBUTE = '/admin/corporate_attribute';
export const PRIVATE_REGISTRATION_EMAIL = '/registration-email';
export const PRIVATE_SEARCH_ACCOUNT = '/accounts/search';
export const PRIVATE_SCREENING_COMPANIES = '/screening-companies';
export const PRIVATE_PING = '/ping';
export const PRIVATE_NOTIFICATION = '/notification';
export const PRIVATE_COMMAND = '/command';
export const PRIVATE_SEND_MISSION_PUBLISHED_EMAIL =
  '/send-mission-published-email';
export const PRIVATE_FEATURES = '/features';
export const PRIVATE_SYSTEM_MESSAGE = '/system_message';
export const PRIVATE_UPDATE_USER_EMAIL = '/admin/user_email';
