import { combineReducers } from 'redux';
import accountReducer from './account';
import uiReducer from './ui';

export default function craeteReducer() {
  return combineReducers({
    account: accountReducer,
    ui: uiReducer,
  });
}
