import React, { ComponentProps, FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 8px 36px;
  color: white;

  &:hover {
    background: #404143;
  }

  &::before {
    content: '-';
    margin-right: 8px;
    font-size: 0.8em;
  }
`;

type SidebarNavLinkProps = ComponentProps<typeof NavLink>;

const SidebarNavLink: FC<SidebarNavLinkProps> = props => {
  return (
    <StyledNavLink
      {...props}
      activeStyle={{
        background: '#404143',
      }}
    />
  );
};

export default SidebarNavLink;
