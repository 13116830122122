import React, { lazy } from 'react';
import { ThemeProvider } from 'styled-components/macro';
// @ts-ignore
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import * as PUBLIC_ROUTES from 'constants/routes/public';
import * as PROTECTED_ROUTES from 'constants/routes/protected';

import { GlobalLayout } from 'components/templates';
import NotFound from 'components/pages/404';

import GlobalStyle from './styles/global';
import theme from './styles/theme';
// import NotFound from "./pages/404";
import createStore from './store';

const Login = lazy(
  () => import(/* webpackChunkName: 'login' */ 'components/pages/Login'),
);
const ResetPassword = lazy(
  () =>
    import(
      /* webpackChunkName: 'resetPassword' */ 'components/pages/Password/Reset/PasswordReset'
    ),
);
const RegisterPassword = lazy(
  () =>
    import(
      /* webpackChunkName: 'registerPassword' */ 'components/pages/Password/Register/PasswordRegister'
    ),
);
const Private = lazy(
  () => import(/* webpackChunkName: 'private' */ 'components/pages/Private'),
);

const store = createStore();

// todo strict type
// @ts-ignore
const persistor = persistStore(store);

function RootRouter() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <GlobalStyle />
        <GlobalLayout>
          <Switch>
            <Route path={PUBLIC_ROUTES.LOGIN} exact component={Login} />
            <Route
              path={PUBLIC_ROUTES.RESET_PASSWORD}
              exact
              component={ResetPassword}
            />
            <Route
              path={PUBLIC_ROUTES.REGISTER_PASSWORD}
              exact
              component={RegisterPassword}
            />
            <Route path={PROTECTED_ROUTES.PRIVATE} component={Private} />
            <Route component={NotFound} />
          </Switch>
        </GlobalLayout>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RootRouter />
      </PersistGate>
    </Provider>
  );
}
