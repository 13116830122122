import { createSelector } from 'reselect';
import { ActionsUnion, createAction } from './helper';

/*
 * Redux Action actionTypes
 */
export const SET_LOADING = 'ui/Loading';

/*
 * Redux Actions
 */
export const UiActions = {
  setLoading: (loading: boolean) => createAction(SET_LOADING, loading),
};
export type Actions = ActionsUnion<typeof UiActions>;

/*
 * Selectors
 */
export const getUi = (state: any): UiState => state.ui;

export const getLoading = createSelector([getUi], ui => ui.loading);

/*
 * Reducer
 */
interface UiState {
  readonly loading: boolean;
}

const initState: UiState = { loading: false };

export default function reducer(state = initState, action: Actions) {
  if (!action) return state;

  switch (action.type) {
    case SET_LOADING: {
      return { ...state, loading: action.payload };
    }
    default:
      return state;
  }
}
