import { setUserId, logEvent } from 'firebase/analytics';
import apiFirebase, { doAnalytics } from 'api/firebase';

// eslint-disable-next-line import/prefer-default-export
export async function trackPage(path: string, userId: string) {
  if (doAnalytics) {
    if (userId) {
      setUserId(apiFirebase.analytics(), userId);
    } else {
      setUserId(apiFirebase.analytics(), '');
    }
    // eslint-disable-next-line
    logEvent(apiFirebase.analytics(), 'page_view', { page_path: path });
  }
}
