import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Toast } from 'visits-style';
import * as PUBLIC_ROUTES from 'constants/routes/public';
import * as PROTECTED_ROUTES from 'constants/routes/protected';
import { CLEAR_ACCOUNT, isAccountLoggedIn } from 'modules/account';
import apiFirebase from 'api/firebase';
import color from 'styles/colors';
import { headerHeight } from 'styles/constants';

const TextLink = styled.span`
  cursor: pointer;
`;

const AppHeader = styled.header`
  box-sizing: border-box;
  height: ${`${headerHeight}px`};
  padding: 20px 40px;
  background: ${color.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
`;

const AppHeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Nav = styled.ul`
  display: flex;
`;

export function Header({ history }: any) {
  const [toasts, setToasts] = useState<any>([]);
  const isLoggedIn = useSelector(isAccountLoggedIn);
  const dispatch = useDispatch();

  const handleLogout = () => {
    apiFirebase.logout().then(() => {
      dispatch({
        type: CLEAR_ACCOUNT,
      });
      setToasts([
        {
          id: 'logout',
          message: 'ログアウトしました',
          color: 'danger',
          duration: 3000,
        },
      ]);
      history.push(PUBLIC_ROUTES.LOGIN);
    });
  };

  const clearToast = (id: string) => {
    setToasts([]);
  };

  useEffect(() => {
    if (isLoggedIn) {
      setToasts([
        {
          id: 'login',
          message: 'ログインしました',
          color: 'success',
          duration: 3000,
        },
      ]);
    }
  }, [isLoggedIn]);

  return (
    <AppHeader>
      <AppHeaderInner>
        <Toast toasts={toasts} clear={clearToast} position="top-right" />
        <Link to={isLoggedIn ? PROTECTED_ROUTES.PRIVATE : PUBLIC_ROUTES.LOGIN}>
          VI Admin
        </Link>
        <Nav>
          {isLoggedIn && (
            <>
              <li>
                <TextLink onClick={handleLogout} data-testid="logout">
                  ログアウト
                </TextLink>
              </li>
            </>
          )}
        </Nav>
      </AppHeaderInner>
    </AppHeader>
  );
}

export default withRouter(Header);
